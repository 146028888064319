import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'
import errorTypes from '../../helpers/toastHelpers/errorTypes'
import { handleError } from '../../helpers/toastHelpers/handleError'
import { handleSuccess } from '../../helpers/toastHelpers/handleSuccess'
import { setItemToLocalStorage } from '../../utils/localStorageUtils/fromLocalStorage'
import { googleLogout } from '../../services/socialLogin'

export function prelogin({ email }) {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${baseAPI}/prelogin`,
      method: 'POST',
      body: JSON.stringify({ email }),
      types: [
        types.PRELOGIN_REQUEST,
        types.PRELOGIN_SUCCESS,
        types.PRELOGIN_ERROR,
      ],
    },
  }
}

export function login({ email, password }) {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${baseAPI}/login`,
      method: 'POST',
      body: JSON.stringify({ email, password }),
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_ERROR],
    },
  }
}

export function loginIDP({ code, code_verifier, redirect_uri }) {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${baseAPI}/login-idp`,
      method: 'POST',
      body: JSON.stringify({ code, code_verifier, redirect_uri }),
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_ERROR],
    },
  }
}

export function loginForm({ email, password }) {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${baseAPI}/login`,
      method: 'POST',
      body: JSON.stringify({ email, password }),
      types: [
        types.LOGIN_FORM_REQUEST,
        types.LOGIN_FORM_SUCCESS,
        types.LOGIN_FORM_ERROR,
      ],
    },
  }
}

export function getSession({ token }) {
  return {
    [RSAA]: {
      endpoint: `${baseAPI}/session`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      types: [
        types.SESSION_REQUEST,
        types.SESSION_SUCCESS,
        types.SESSION_ERROR,
      ],
    },
  }
}

export function logout() {
  return {
    payload: {},
    type: types.LOGOUT_SUCCESS,
  }
}

export function changeEmailRequest({ email }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      endpoint: `${baseAPI}/change-email/${email}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      types: [
        types.CHANGE_EMAIL_REQ_REQUEST,
        types.CHANGE_EMAIL_REQ_SUCCESS,
        types.CHANGE_EMAIL_REQ_ERROR,
      ],
    },
  }
}

export function changeEmail({ verificationCode, email }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      endpoint: `${baseAPI}/change-email`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ verificationCode, newEmail: email }),
      types: [
        types.CHANGE_EMAIL_REQUEST,
        types.CHANGE_EMAIL_SUCCESS,
        types.CHANGE_EMAIL_ERROR,
      ],
    },
  }
}

export const createClientIDP =
  ({ code, code_verifier, redirect_uri, query }) =>
  (dispatch) => {
    dispatch({
      [RSAA]: {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, code_verifier, redirect_uri }),
        endpoint: `${baseAPI}/signup-idp?${query}`,
        method: 'POST',
        types: [
          types.CREATE_CLIENT_REQUEST,
          {
            type: types.CREATE_CLIENT_SUCCESS,
            payload: async (action, state, res) => {
              const json = await res.json()

              setItemToLocalStorage('token', json.token)
              handleSuccess('Usuario registrado correctamente')

              return json
            },
          },
          {
            type: types.CREATE_CLIENT_ERROR,
            payload: async (action, state, res) => {
              const json = await res.json()

              handleError(errorTypes.GENERIC_ERROR)

              setTimeout(() => googleLogout(), 2000)

              return json
            },
          },
        ],
      },
    })
  }

export const createClient =
  ({ user }) =>
  (dispatch) => {
    dispatch({
      [RSAA]: {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
        endpoint: `${baseAPI}/signup`,
        method: 'POST',
        types: [
          types.CREATE_CLIENT_REQUEST,
          {
            type: types.CREATE_CLIENT_SUCCESS,
            payload: async (action, state, res) => {
              const json = await res.json()

              const value = json.token
              setItemToLocalStorage('token', value)
              handleSuccess('Usuario registrado correctamente')

              return json
            },
          },
          {
            type: types.CREATE_CLIENT_ERROR,
            payload: async (action, state, res) => {
              const json = await res.json()

              if (json.message && json.message.includes('email_1 dup key:')) {
                handleError(errorTypes.DUPLICATE_EMAIL)
              } else if (
                json.message &&
                json.message.includes('Password did not conform with policy')
              ) {
                handleError(errorTypes.PASSWORD_POLICY)
              } else {
                handleError(errorTypes.GENERIC_ERROR)
              }

              return json
            },
          },
        ],
      },
    })
  }
