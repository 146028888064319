import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PageLayout from '../layouts/Page'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from '../components/common/Loading'
import GoogleButton from 'react-google-button'
import { toast } from 'react-toastify'
import { googleSignUp } from '../services/socialLogin'
import { AWS_REDIRECT_URI_SIGNUP, ENABLE_SOCIAL_LOGIN } from '../config'
import pigeonsLetters from '../assets/images/37.png'
import { createClient, createClientIDP } from '../redux/actions/auth'

const ClientSignUpPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { activeUser, loading } = useSelector((state) => state.auth)

  const [searchParams, setSearchParams] = useState({})
  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    last_name: '',
    phone: '',
    password: '',
  })

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const sessionUtmParamsMemo = useMemo(
    () => sessionStorage.getItem('utmParams'),
    []
  )

  const updateUtmParams = useCallback(() => {
    if (
      query.get('utm_source') ||
      query.get('utm_campaign') ||
      query.get('utm_medium')
    ) {
      const utmParams = `utm_source=${query.get(
        'utm_source'
      )}&utm_medium=${query.get('utm_medium')}&utm_campaign=${query.get(
        'utm_campaign'
      )}`
      if (!sessionUtmParamsMemo) sessionStorage.setItem('utmParams', utmParams)
      setSearchParams({
        utm_source: query.get('utm_source') || '',
        utm_medium: query.get('utm_medium') || '',
        utm_campaign: query.get('utm_campaign') || '',
      })
    }
  }, [query, sessionUtmParamsMemo])

  const handleOAuth = useCallback(() => {
    if (query.get('state') && query.get('code') && !query.get('error')) {
      const code_verifier = sessionStorage.getItem(
        `code_verifier-${query.get('state')}`
      )
      sessionStorage.removeItem(`code_verifier-${query.get('state')}`)
      console.log("code_verifier",code_verifier)

      if (code_verifier === null) {
        console.error('Code verifier not found')
        return
      }
      dispatch(
        createClientIDP({
          code: query.get('code'),
          code_verifier,
          redirect_uri: AWS_REDIRECT_URI_SIGNUP,
          query: sessionUtmParamsMemo,
        })
      )
    }
  }, [query, sessionUtmParamsMemo, dispatch])

  useEffect(() => {
    updateUtmParams()
    handleOAuth()

    if (query.get('repeat') && !query.get('error')) {
      googleSignUp()
    }

    if (query.get('error')) {
      if (query.get('error_description')?.startsWith('Already found')) {
        navigate(`/client-signup?repeat=true`)
      } else {
        toast.error(
          `Ha ocurrido un error, contacte con el administrador, ${query.get(
            'error_description'
          )}`,
          {
            theme: 'colored',
          }
        )
        navigate(`/client-signup`)
      }
    }
  }, [
    location.search,
    query,
    sessionUtmParamsMemo,
    dispatch,
    navigate,
    updateUtmParams,
    handleOAuth,
  ])

  useEffect(() => {
    if (activeUser && activeUser._id) {
      navigate('/thanks-client')
    }
  }, [activeUser, navigate])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const user = { ...formValues, role: 'client' }
    user['utm_source'] = searchParams.utm_source
    user['utm_medium'] = searchParams.utm_medium
    user['utm_campaign'] = searchParams.utm_campaign

    dispatch(createClient({ user }))
  }

  const showGoogle = true

  return (
    <PageLayout className="text-center">
      <Container fluid className="vh-100 d-flex align-items-center">
        <Row className="w-100 align-items-center">
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center p-4"
          >
            {loading ? (
              <Loading loading={loading} />
            ) : (
              <>
                <div className="text-center mt-4 mb-4">
                  <h1 className="h2">
                    <span role="img" aria-label="rocket">
                      Empieza Hoy con Legal Pigeon 🚀
                    </span>
                  </h1>
                  <p className="lead">
                    Únete ahora y mejora la optimización de tus procesos legales
                    en minutos.
                  </p>
                </div>

                <Card className="w-100 shadow-sm" style={{ maxWidth: '500px' }}>
                  <CardBody>
                    {showGoogle && (
                      <>
                        <div className="d-flex justify-content-around mt-3 mb-4">
                          <GoogleButton
                            type="light"
                            onClick={googleSignUp}
                            label="Registrate con Google"
                          />
                        </div>
                        <div className="text-center bold mb-4">
                          o rellena el formulario
                        </div>
                      </>
                    )}
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="mb-3">
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          value={formValues.email}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label for="name">Nombre</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          value={formValues.name}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label for="last_name">Apellidos</Label>
                        <Input
                          type="text"
                          name="last_name"
                          id="last_name"
                          className="form-control"
                          value={formValues.last_name}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <Label for="phone">Teléfono</Label>
                        <Input
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-control"
                          value={formValues.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Label for="password">Contraseña</Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          className="form-control"
                          value={formValues.password}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <Button type="submit" color="primary" className="w-100">
                        Registrarse
                      </Button>
                    </Form>
                    <div className="mt-4">
                      <small>
                        Ya tienes una cuenta en Legal Pigeon?{' '}
                        <Link to="/login">Inicia sesión</Link>
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center p-0"
          >
            <img
              src={pigeonsLetters}
              alt="Legal Pigeon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default ClientSignUpPage
