import React, { useState, useEffect } from 'react'
import { baseAPI, STRIPE_KEY } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { createExpedient } from '../../redux/actions/expedient'
import CardForm from '../../components/CardForm'
import PaymentSummary from '../../components/PaymentSummary'
import Loading from '../../components/common/Loading'
import { Row, Col, CardBody } from 'reactstrap'
import action from '../../mixPanelsActions'
import moment from 'moment'
import { gtmEvent } from '../../services/gtm-handler'
import { isHearingUrgent } from '../../services'
import { useNavigate } from 'react-router'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(STRIPE_KEY)

const PayingProcess = ({ user, expedient }) => {
  const navigate = useNavigate()
  const createdExpedient = useSelector((state) => state.expedient.created)

  const [loading, setLoading] = useState(false)
  const [checkoutIntent, setCheckoutIntent] = useState({})
  const defaultPromoState = {
    showSubmitPromotionButton: false,
    name: '',
    promotionValidation: null,
    active: false,
    percentatge: false,
    value: false,
  }
  const [promotion, setPromotion] = useState(defaultPromoState)

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !expedient.judicial_party ||
      expedient.hearing_type < 0 ||
      !expedient.hearing_date ||
      !expedient.dificulty ||
      !expedient.court ||
      !expedient.resume ||
      !user.name ||
      !user.last_name
    ) {
      navigate('/create-expedient-form/name')
    }
  }, [expedient, user, navigate])

  useEffect(() => {
    const fetchCheckoutToken = async () => {
      setLoading(true)
      try {
        const res = await fetch(`${baseAPI}/checkout-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            user_id: user._id,
            isUrgentHearing: expedient.is_urgent_hearing,
          }),
        })

        if (res.status === 200) {
          const data = await res.json()
          setCheckoutIntent(data.token)
          setLoading(false)
          action.identify(user._id)
          action.track('HearingShoppingCart', {
            first_name: expedient.name,
            last_name: expedient.last_name,
            email: expedient.email,
            ...expedient,
            hearing_date: moment(expedient.hearing_date).format(
              '[Dia:] DD/MM/YYYY [Hora:] hh:mm A'
            ),
            hearing_type: expedient.hearingTypeValue,
            judicial_party: expedient.judicialPartyValue,
          })
        } else {
          setLoading(false)
          throw new Error('Failed to fetch checkout token')
        }
      } catch (_error) {
        setLoading(false)
      }
    }

    fetchCheckoutToken()
  }, [expedient, user])

  const handleCreateExpedient = (exp) => {
    exp.user_invoice_account_id = 'account_not_set'
    exp.is_urgent_hearing = isHearingUrgent(exp.hearing_date)

    dispatch(createExpedient({ expedient: exp }))
  }

  useEffect(() => {
    if (createdExpedient) {
      gtmEvent({
        event: 'purchase',
        ecommerce: {
          currencyCode: 'EUR',
          purchase: {
            actionField: {
              id: createdExpedient._id,
              revenue: createdExpedient.price,
              tax: createdExpedient.price * 0.21,
              creatorId: createdExpedient.creator_id,
            },
            products: createdExpedient.is_urgent_hearing
              ? [
                  {
                    id: 'VP-L',
                    name: 'Vista prepagada form landing',
                    price:
                      createdExpedient.price -
                      createdExpedient.extra_urgent_price,
                    quantity: 1,
                  },
                  {
                    id: 'VU',
                    name: 'Vista urgente',
                    price: createdExpedient.extra_urgent_price,
                    quantity: 1,
                  },
                ]
              : [
                  {
                    id: 'VP-L',
                    name: 'Vista prepagada form landing',
                    price: createdExpedient.price,
                    quantity: 1,
                  },
                ],
          },
        },
      })

      gtmEvent({
        event: 'purchase',
        ecommerce: {
          transaction_id: createdExpedient._id,
          value: createdExpedient.price,
          tax: createdExpedient.price * 0.21,
          currency: 'EUR',
          creatorId: createdExpedient.creator_id,
          items: createdExpedient.is_urgent_hearing
            ? [
                {
                  item_name: 'Vista prepagada form landing',
                  item_id: 'VP-L',
                  price:
                    createdExpedient.price -
                    createdExpedient.extra_urgent_price,
                  currency: 'EUR',
                  quantity: 1,
                },
                {
                  item_name: 'Vista urgente',
                  item_id: 'VU',
                  price: createdExpedient.extra_urgent_price,
                  currency: 'EUR',
                  quantity: 1,
                },
              ]
            : [
                {
                  item_name: 'Vista prepagada form landing',
                  item_id: 'VP-L',
                  price: createdExpedient.price,
                  currency: 'EUR',
                  quantity: 1,
                },
              ],
        },
      })

      fetch(`${baseAPI}/reset/${user.email}?setPassword=true`)
      navigate('/create-expedient-form/last-step')
    }
  }, [createdExpedient, navigate, user.email])

  return (
    <>
      <Loading loading={loading} />
      <CardBody>
        <div className="d-flex justify-content-center align-items-center">
          <h2 style={{ lineHeight: '1.5' }} className="col-12 text-center mb-4">
            Gracias por tu confianza
          </h2>
        </div>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md="4">
            <PaymentSummary
              basePrice={95 + expedient.extra_urgent_price}
              promotion={promotion}
              setPromotion={setPromotion}
              defaultPromoState={defaultPromoState}
              expedient={expedient}
            />
          </Col>
        </Row>

        <Loading loading={!checkoutIntent} />
        <div className="d-flex justify-content-center align-items-center m-0">
          <Row className="d-flex justify-content-center align-items-center m-0">
            <Elements className="border" locale="es" stripe={stripePromise}>
              <CardForm
                className="border"
                activeUser={user}
                basePrice={95 + expedient.extra_urgent_price}
                cards={[]}
                checkoutIntent={checkoutIntent}
                expedient={expedient}
                createExpedient={handleCreateExpedient}
                process={'payingProcess'}
              />
            </Elements>
          </Row>
        </div>
      </CardBody>
    </>
  )
}

export default PayingProcess
