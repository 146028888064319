import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  promotions: {},
  promotion: {},
  created: {},
}

export const promotion = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotions: { ...state.promotions, ...action.payload },
      }

    case types.GET_PROMOTIONS_ERROR:
      toast.error(
        'Ha ocurrido un error, cargando las promociones si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.GET_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: { ...action.payload },
      }

    case types.GET_PROMOTION_ERROR:
      toast.error(
        'Ha ocurrido un error, cargando la promocion si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.CREATE_PROMOTION_SUCCESS:
      toast.success('Promoción creada 😀', { theme: 'colored' })

      return {
        ...state,
        promotions: { ...state.promotions, ...action.payload },
        created: action.payload,
      }

    case types.CREATE_PROMOTION_ERROR:
      toast.error(action.payload.response.message, { theme: 'colored' })
      return state

    case types.UPDATE_PROMOTION_SUCCESS:
      toast.success('Promoción actualizada 😀', { theme: 'colored' })
      return {
        ...state,
        promotion: action.payload.promotion,
      }

    case types.UPDATE_PROMOTION_ERROR:
      toast.error(
        'Ha ocurrido un error, actualizando la promocion si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return {
        ...state,
        promotion: action.payload.promotion,
      }

    case types.VALIDATE_PROMOTION_SUCCESS:
      if (action.payload.err) {
        toast.error('Promo invalida', { theme: 'colored' })
        return state
      } else {
        toast.success('Promoción validada', { theme: 'colored' })

        return {
          ...state,
          promotion: {
            ...state.promotion,
            ...action.payload,
          },
        }
      }

    case types.VALIDATE_PROMOTION_ERROR:
      toast.error(
        'Ha ocurrido un error, validando la promoción si persiste contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    default:
      return state
  }
}
